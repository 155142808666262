import React, { useRef, useState, useEffect } from 'react'
import logger from 'logger'
import config from 'config'


const loadImage = (src: string): Promise<void> =>
  new Promise((resolve, reject) => {
    const img = new window.Image()

    img.onload = () => resolve()
    img.onerror = (err) => reject(err)

    img.src = src
  })

const getResizeSrc = ({ src, nodeSizes, additionalQuery }) => {
  const isFromS3 = typeof src === 'string' ? src.startsWith(config.storage) : false

  const multiplier = window.devicePixelRatio || 1

  if (isFromS3) {
    const storageCity = config.storage.replace(/.*\//, '')
    const [ bucketUrl, path ] = src.split(`/${storageCity}/`)
    const w = (nodeSizes.find(({ w }) => w)?.w * multiplier) || '-'
    const h = (nodeSizes.find(({ h }) => h)?.h * multiplier) || '-'

    return `${bucketUrl}/${w}/${h}/${storageCity}/${path}`
  }

  const prefix = /\?/.test(src) ? '&' : '?'
  const resizeQuery = `${prefix}${nodeSizes.map(({ w, h }) => w ? `w=${w * multiplier}` : `h=${h * multiplier}`).join('&')}`

  return `${src}${resizeQuery}${additionalQuery}`
}
const getZinaSrc = ({ node, src, additionalQuery = '', height }: { node: HTMLImageElement, src: string, additionalQuery?: string, height?: string }) => {
  const width = node.clientWidth
  const nodeSizes = []

  // Check how it works, Denis can't resize properly by both sizes
  // https://test.rosatom.city/ajax.php?resizePhoto=/upload/city36/9945/scratch1630432151682.jpeg&w=144&h=144
  if (height) {
    nodeSizes.push({ h: height })
  }
  else if (width) {
    nodeSizes.push({ w: width })
  }

  // Fix for immortals, resize doesn't work if there is no height
  if (/immortals/.test(src)) {
    const size = nodeSizes[0].w ? 'h' : 'w'

    nodeSizes.push({
      [size]: Object.values(nodeSizes[0])[0],
    })
  }

  if (!nodeSizes.length) {
    return src
  }

  return getResizeSrc({
    src,
    nodeSizes,
    additionalQuery,
  })
}

const getPlaceholder = (aspect = 1) => `data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 ${aspect} 1'%3e%3c/svg%3e`

type UseZinaImageProps = {
  src: string
  fallback?: string | (() => void)
  aspect?: number
  withLoader?: boolean
  additionalQuery?: string
  height?: string
  skip?: boolean
  onLoad?: () => void
}

type UseZinaImageResult = {
  nodeRef: React.RefObject<HTMLImageElement>
  zinaSrc: string
  isLoading: boolean
}

const useZinaImage = (props: UseZinaImageProps): UseZinaImageResult => {
  const { src, fallback, aspect = 1, withLoader = false, additionalQuery, height, skip, onLoad } = props

  const nodeRef = useRef<HTMLImageElement>(null)
  const isFirstRenderRef = useRef<boolean>(true)

  const [ { zinaSrc, isLoading }, setState ] = useState({
    zinaSrc: getPlaceholder(aspect),
    isLoading: withLoader && !skip,
  })

  useEffect(() => {
    if (skip) {
      return
    }

    if (!src) {
      logger.error('No src passed to Image component')
      return
    }

    // if new "src" comes from props then need reset state
    if (!isFirstRenderRef.current) {
      setState({
        zinaSrc: getPlaceholder(aspect),
        isLoading: withLoader,
      })
    }

    isFirstRenderRef.current = false

    const zinaSrc = getZinaSrc({
      node: nodeRef.current,
      src,
      additionalQuery,
      height,
    })

    const setSrc = (src) => {
      setState({
        zinaSrc: src,
        isLoading: false,
      })
    }

    loadImage(zinaSrc)
      .then(() => {
        setSrc(zinaSrc)
        onLoad?.()
      })
      .catch((err) => {
        logger.error(err)
        if (typeof fallback === 'function') {
          fallback()
        }
        else {
          setSrc(fallback || src)
        }
      })
  }, [ src, fallback, aspect, withLoader, skip ])

  return {
    nodeRef,
    zinaSrc,
    isLoading,
  }
}


export default useZinaImage
